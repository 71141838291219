const data = [
  {
    name: "Ms. Archana Soni",
    desc: "Chairperson",
  },
  {
    name: "Mr. Sanjay Rai Saxena",
    desc: "Vice Chairman (Tr. Rep)",
  },
  {
    name: "Ms. Jyoti K. Nambiar",
    desc: "Vice Chairperson (Parent Rep.)",
  },
  {
    name: "Mr. Arvind Bhardwaj",
    desc: "Secretary (Parent Rep)",
  },
  {
    name: "Ms. Swati Majumdar",
    desc: "Joint Secretary (Tr. Rep)",
  },
  {
    name: "Mr. Nair Radha Krishnan R",
    desc: "Joint Secretary (Parent Rep)",
  },
  {
    name: "Ms. Guranjan Kaur Bhatia",
    desc: "Hony. Treasurer",
  },
  {
    name: "Ms. Reeni Gulati",
    desc: "Teacher Rep.",
  },
  {
    name: "Ms. Vandana Gera",
    desc: "Teacher Rep.",
  },
  {
    name: "Ms. Alka Rohil",
    desc: "Teacher Rep.",
  },
  {
    name: "Mrs. Bhawna Sethi",
    desc: "Teacher Rep.",
  },
  {
    name: "Dr Prashant Gupta",
    desc: "Parent Rep.",
  },
  {
    name: "Mr. Satish Mishra",
    desc: "Parent Rep.",
  },
  {
    name: "Ms. Ritu Antil",
    desc: "Parent Rep.",
  },
  {
    name: "Dr. S Pramila",
    desc: "Parent Rep.",
  },
  {
    name: "Mr. Puneet Tiwari",
    desc: "Parent Rep.",
  },
];

export default data;
